<template id="nav-tag">
  <div class="main-info">
    <div class="back-info" @click="moveLeft">
      <Icon type="ios-arrow-back"/>
    </div>
    <div ref="navBody" class="nav-body">
      <div ref="navMain" :style="{display:'flex', 'white-space': 'nowrap',transform: 'translate('+countTransform+'px)'}">
        <div v-for="(item,index) in menuList" :ref="item.is_active ==1? 'refSelected' :''" class="tag-info">
          <router-link :to="item.module">
            <span style="padding: 0 10px;" :class="item.is_active ==1 ? 'tag-selected':'tag-info-c'" @click="tagClick(item)">{{item.name}}</span>
          </router-link>
          <i v-show="item.name!='首页'" class="ivu-icon ivu-icon-ios-close ivu-tabs-close" size="20" @click="closeItem(index)"></i>
        </div>
      </div>
    </div>
    <div class="forward-info pointer" @click="moveRight">
      <Icon type="ios-arrow-forward"/>
    </div>
  </div>
</template>

<script>

export default {
  name: "NavTag",
  data() {
    return {
      menuInfo: [
        {
          'name': '货品列表',
          'select_num': 1,
          'module': '/goodsManage',
          'manu_name': 'GoodsManage'
        },
        {
          'name': '货品品类',
          'select_num': 2,
          'module': '/goodsManage',
          'manu_name': 'GoodsManage'
        },
        {
          'name': '入库单',
          'select_num': 3,
          'module': '/goodsManage',
          'manu_name': 'GoodsManage'
        },
        {
          'name': '出库单',
          'select_num': 4,
          'module': '/goodsManage',
          'manu_name': 'GoodsManage'
        },
        {
          'name': '发货单',
          'select_num': 5,
          'module': '/goodsManage',
          'manu_name': 'GoodsManage'
        },
        {
          'name': '仓库概况',
          'select_num': 11,
          'module': '/goodsManage',
          'manu_name': 'GoodsManage'
        },
        {
          'name': '货品盘点',
          'select_num': 8,
          'module': '/goodsManage',
          'manu_name': 'GoodsManage'
        },
        {
          'name': '库存调拨',
          'select_num': 9,
          'module': '/goodsManage',
          'manu_name': 'GoodsManage'
        },
        {
          'name': '库存变动',
          'select_num': 10,
          'module': '/goodsManage',
          'manu_name': 'GoodsManage'
        },
        {
          'name': '领料记录',
          'select_num': 6,
          'module': '/goodsManage',
          'manu_name': 'GoodsManage'
        },
        {
          'name': '领料货品',
          'select_num': 7,
          'module': '/goodsManage',
          'manu_name': 'GoodsManage'
        },

        {
          'name': '生产领料货品',
          'select_num': 5,
          'module': '/createManage',
          'manu_name': 'CreateRequire'
        },

        {
          'name': '生产操作',
          'select_num': 4,
          'module': '/createManage',
          'manu_name': 'CreateRequire'
        },
        {
          'name': '生产计划',
          'select_num': 2,
          'module': '/createManage',
          'manu_name': 'GoodsCreatePlan'
        },
        {
          'name': 'BOM表',
          'select_num': 1,
          'module': '/createManage',
          'manu_name': 'CreateRequire'
        },


        {
          'name': '采购列表',
          'select_num': 1,
          'module': '/buyManage',
          'manu_name': 'BuyManage'
        },
        {
          'name': '采购退货',
          'select_num': 3,
          'module': '/buyManage',
          'manu_name': 'BuyManage'
        },

        {
          'name': '销售列表',
          'select_num': 1,
          'module': '/saleManage',
          'manu_name': 'SaleManage'
        },
        {
          'name': '销售退货',
          'select_num': 2,
          'module': '/saleManage',
          'manu_name': 'SaleManage'
        },
        {
          'name': '售价配置',
          'select_num': 3,
          'module': '/saleManage',
          'manu_name': 'SaleManage'
        },
        {
          'name': '销售合同',
          'select_num': 7,
          'module': '/saleManage',
          'manu_name': 'SaleManage'
        },
        {
          'name': '货品销量',
          'select_num': 4,
          'module': '/saleManage',
          'manu_name': 'SaleManage'
        },
        {
          'name': '客户售额',
          'select_num': 5,
          'module': '/saleManage',
          'manu_name': 'SaleManage'
        },
        {
          'name': '打印配置',
          'select_num': 6,
          'module': '/saleManage',
          'manu_name': 'SaleManage'
        },

        {
          'name': '财务概览',
          'select_num': 1,
          'module': '/finance',
          'manu_name': 'Finance'
        },
        {
          'name': '采购流水',
          'select_num': 2,
          'module': '/finance',
          'manu_name': 'Finance'
        },
        {
          'name': '销售流水',
          'select_num': 3,
          'module': '/finance',
          'manu_name': 'Finance'
        },
        {
          'name': '采购退货流水',
          'select_num': 4,
          'module': '/finance',
          'manu_name': 'Finance'
        },
        {
          'name': '销售退货流水',
          'select_num': 5,
          'module': '/finance',
          'manu_name': 'Finance'
        },
        {
          'name': '杂项结算',
          'select_num': 6,
          'module': '/finance',
          'manu_name': 'Finance'
        },
        {
          'name': '运费流水',
          'select_num': 7,
          'module': '/finance',
          'manu_name': 'Finance'
        },
      ],
      menuList: [],

      countTransform: 0,
    };
  },
  mounted() {
    let countTransform = localStorage.getItem("countTransform")
    if (countTransform !== undefined) {
      this.countTransform = parseInt(countTransform);
    }

    if (isNaN(countTransform)) {
      this.countTransform = 0;
    }
  },
  methods: {
    closeItem(index) {
      this.menuList.splice(index, 1);
      localStorage.setItem("menu_list", JSON.stringify(this.menuList));
    },
    moveRight() {
      const navBody = this.$refs.navBody
      const parentDiv = this.$refs.navMain;
      if (navBody.offsetWidth <= parentDiv.offsetWidth) {
        this.setCountTransform(navBody.offsetWidth - parentDiv.offsetWidth + 12)
      }
    },
    moveLeft() {
      this.setCountTransform(0);
    },
    setNavList(moduleInfo, activeInfo) {
      let menuList = JSON.parse(localStorage.getItem("menu_list"));
      if (!menuList) {
        menuList = [{
          'name': '首页',
          'select_num': 0,
          'module': '/'
        }]
      }

      // if (this.menuList.length >= 9) {
      //   this.menuList.pop()
      // }

      for (let i = 0; i < this.menuInfo.length; i++) {
        let item = this.menuInfo[i]
        if (item.module == moduleInfo && item.select_num == activeInfo) {
          let addFlag = 0;
          for (let j = 0; j < menuList.length; j++) {
            menuList[j].is_active = 0;

            if (menuList[j].name == item.name) {
              menuList[j].is_active = 1;
              addFlag = 1;
            }
          }

          if (!addFlag) {
            item.is_active = 1;
            menuList.push(item)
          }

          this.menuList = menuList
          localStorage.setItem("menu_list", JSON.stringify(menuList));
          break;
        }
      }

      setTimeout(() => {
        this.changeOffset()
      }, 100);
    },
    tagClick(item) {
      localStorage.setItem(item.manu_name, item.select_num);
      this.$emit('func', item.select_num);
    },
    changeOffset() {
      const navBody = this.$refs.navBody;
      const currentDiv = this.$refs.refSelected;
      if (currentDiv !== undefined && currentDiv.length > 0) {
        if (currentDiv[0].offsetLeft + this.countTransform < 0) {
          this.setCountTransform(this.countTransform - (currentDiv[0].offsetLeft + this.countTransform))
        }

        if (currentDiv[0].offsetLeft + this.countTransform + 112 > navBody.offsetWidth) {
          this.setCountTransform(this.countTransform + (navBody.offsetWidth - (currentDiv[0].offsetLeft + this.countTransform)) - 112)
        }
      }
    },
    setCountTransform(countTransform) {
      this.countTransform = countTransform;
      localStorage.setItem("countTransform", countTransform);
    }
  },
  created() {

  },
};
</script>

<style scoped>
.tag-info {
  background: #fff;
  height: 32px;
  line-height: 32px;
  padding: 0 12px;
  cursor: pointer;
  margin-right: 12px;
  border-radius: 3px;
}

.nav-body {
  width: calc(100% - 64px);
  display: flex;
  overflow: hidden;
}

.main-info {
  background: #f5f7f9;
  height: 44px;
  padding: 6px 6px;
  width: 100%;
  display: flex;
  align-items: center;
}

.back-info {
  width: 32px;
  font-size: 16px;
  color: #808695;
  text-align: center;
  cursor: pointer;
}

.forward-info {
  width: 32px;
  font-size: 16px;
  color: #808695;
  text-align: center;
}

</style>

<style>
.tag-info-c {
  color: #808695 !important;
}

.tag-selected {
  color: #2775ff !important;
}
</style>
